import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { Section, SectionPageTitle, SectionBanner, BreadCrumb } from "../components/section"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SitemapBanner from "../images/privacy-policy-banner.jpg"
import BreakpointUp from "../components/Media/BreakpointUp"

const SitemapGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 30px;
    ${BreakpointUp.sm`    
        grid-template-columns: repeat(3, 1fr);     
    `}
`
const SitemapItem = styled.div`
    & h2{
        font-weight: 700;
        font-size: 28px;
        line-height:38px;
        margin-bottom:10px;
        & a{
            color:#020304;
            &:hover {
                color:#020304;
            }
        }
    }
    & ul{
        & li{
            & a{
                color: rgba(102, 102, 102, 0.7);
                &:hover {
                    color:#020304;
                }
            }  
        }
    }

`
const SitemapPage = ({ data, location }) => {
    const products = data.allContentfulProduct
    const len = data.allContentfulProduct.edges.length
    return(
        <Layout location={location}>
            <SEO title="Sitemap"/>
            <SectionBanner bgImg={`url(${SitemapBanner})`}>
                <div className="container">
                    <BreadCrumb><Link to='/'>Home</Link>/<span>Sitemap</span></BreadCrumb>   
                    <SectionPageTitle mt="30px" mb="0px" textAlign="center">Sitemap</SectionPageTitle>
                </div>
            </SectionBanner>
            <Section pt="90px" pb="90px" xpt="60px" xpb="60px" maxWidth="1120px">     
                <div className="container">   
                    <SitemapGrid>
                        <SitemapItem>
                            <h2><Link to='/commercial-metal-buildings'>Commercial Buildings</Link></h2>
                            <ul>
                                <li><Link to='/custom-metal-buildings'>Custom Buildings</Link></li>
                                <li><Link to='/steel-utility-buildings'>Utility Buildings</Link></li>
                            </ul>                
                            <h2><Link to='/metal-garages'>Garages</Link></h2>
                            <ul>
                                <li><Link to='/vertical-roof-garages'>Vertical Roof Garages</Link></li>                
                                <li><Link to='/a-frame-roof-garages'>A-Frame Roof Garages</Link></li>
                                <li><Link to='/regular-roof-garages'>Regular Roof Garages</Link></li>
                            </ul>
                            <h2><Link to='/metal-barns'>Barns</Link></h2>
                            <ul>
                                <li><Link to='/vertical-roof-barns'>Vertical Roof Barns</Link></li>                
                                <li><Link to='/a-frame-roof-barns'>A-Frame Roof Barns</Link></li>
                                <li><Link to='/regular-roof-barns'>Regular Roof Barns</Link></li>                    
                                <li><Link to='/continuous-roof-barns'>Continuous Roof Barns</Link></li>
                            </ul>
                            <h2><Link to='/metal-rv-covers'>RV Covers</Link></h2>
                            <ul>
                                <li><Link to='/vertical-roof-rv-covers'>Vertical RV Covers</Link></li>                
                                <li><Link to='/a-frame-roof-rv-covers'>A-Frame RV Covers</Link></li>
                                <li><Link to='/regular-roof-rv-covers'>Regular RV Covers</Link></li>
                            </ul>
                            <h2>Resources</h2>
                            <ul>
                                <li><Link to='/rto'>RTO</Link></li>                
                                <li><Link to='/financing'>Financing</Link></li>
                                <li><Link to='/site-preparation'>Site Preprepation</Link></li>                    
                                <li><Link to='/order-process'>Building Order Process</Link></li>
                                <li><Link to='/metal-building-roof-styles'>Building Roof Styles</Link></li>
                                <li><Link to='/article'>Blog</Link></li>
                                <li><Link to='/faq'>FAQs</Link></li>
                            </ul>
                            <h2><Link to='/about-us'>About Us</Link></h2>
                            <ul>
                                <li><Link to='/about-us'>About Us</Link></li>                
                                <li><Link to='/contact-us'>Contact Us</Link></li>
                            </ul>          
                        </SitemapItem>
                        <SitemapItem>
                            <h2><Link to='/products'>Products</Link></h2>
                            <ul>
                                {
                                    products.edges.map((item, i)=>{
                                        const productURL = (item.node.productCategory.link + "/" + item.node.url).replace('//', '/');
                                        if (i < len/2) {
                                            return(
                                                <li><Link to={`${productURL}`}>{item.node.productName}</Link></li>
                                            )
                                        }
                                    })
                                }
                            </ul>           
                        </SitemapItem>
                        <SitemapItem>
                            <ul>
                                {
                                    products.edges.map((item, i)=>{
                                        const productURL = (item.node.productCategory.link + "/" + item.node.url).replace('//', '/');
                                        if (i >= len/2) {
                                            return(
                                                <li><Link to={`${productURL}`}>{item.node.productName}</Link></li>
                                            )
                                        }
                                    })
                                }
                            </ul>           
                        </SitemapItem>
                    </SitemapGrid>              
                </div>
            </Section>    
        </Layout>
    )
} 

export default SitemapPage

export const pageQuery = graphql`
  query SitemapPageQuery {
    allContentfulProduct {
      edges {
        node {
          id
          productName
          url
          productCategory {
            link
          }
        }
      }
    }
  }
`